import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

// image imports
import rowdyTeam from '../assets/img/project_updates/rowdyTeam.jpg';
import continuedDev from '../assets/img/project_updates/continuedDev.jpg';
import frs from '../assets/img/project_updates/frs.jpg';
import communityHour1 from '../assets/img/project_updates/communityHour1.jpg';
import presidentsCabinet from '../assets/img/project_updates/presidentsCabinet.jpg';
import oer from '../assets/img/project_updates/oerCelebration.jpg';
import csABM from '../assets/img/project_updates/csAdvisoryBoardMeeting.jpg';
import womensVb from '../assets/img/project_updates/womensVb.jpg';
import mensBb from '../assets/img/project_updates/mensBb.jpg';
import communityHour2 from '../assets/img/project_updates/communityHour2.jpg';
import cacedInterview from '../assets/img/project_updates/cacedInterview.jpg';
import launch from '../assets/img/project_updates/launch.jpg';
import newTeam from '../assets/img/project_updates/newTeam.jpg';

import './ProjectUpdates.css';
import updateData from './updateData.json'; // update data is held in the updateData.json file

const ProjectCard = ({ imageSrc, title, description, link, linkMessage }) => (
    <Card className="project-card">
        <Card.Img variant="top" src={imageSrc} className="project-card-img" />
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
            {link && ( // if a link is needed for an update
                <Card.Link
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {linkMessage || 'Learn More'}
                </Card.Link>
            )}
        </Card.Body>
    </Card>
);
ProjectCard.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    link: PropTypes.string,
    linkMessage: PropTypes.string,
};

// creating an image map to link to the key (imageSrc) in the json file
const imageMap = {
    rowdyTeam,
    continuedDev,
    frs,
    communityHour1,
    presidentsCabinet,
    oer,
    csABM,
    womensVb,
    mensBb,
    communityHour2,
    cacedInterview,
    launch,
    newTeam,
};

// looping through the updates objects list recreating objects with the images
// in the image map that we just created
// this is needed to replace string-based image keys from the json file with
// actual imported image files
const updates = updateData.map((update) => ({
    ...update,
    imageSrc: imageMap[update.imageSrc],
}));

export default function ProjectUpdates() {
    return (
        <section id="projectUpdates">
            <Container className="py-4">
                <h2 className="text-center mb-5">Project Updates</h2>
                <div className="scroll-hint">
                    Scroll down for older updates and to see where we came from
                    ↓
                </div>
                <Row className="project-row">
                    <div className="timeline-line" />
                    {updates
                        .slice()
                        .reverse()
                        .map((update, index) => (
                            <Col
                                key={index}
                                md={12}
                                className="mb-5 timeline-item"
                            >
                                <div className="update-date">{update.date}</div>
                                <ProjectCard {...update} />
                            </Col>
                        ))}
                </Row>
            </Container>
        </section>
    );
}
